import gql from 'graphql-tag'

function generateRelationQuery(object: any, key: any) {
  const relationObject = object[key]
  if (typeof relationObject === 'object') {
    const arrayAnd = []
    for (const nestedObject in relationObject) {
      if (typeof relationObject[nestedObject] === 'object' && 'column' in relationObject[nestedObject]) {
        if (relationObject[nestedObject].value !== undefined && relationObject[nestedObject].value !== '' && relationObject[nestedObject].value !== null) {
          const value = getValue(relationObject[nestedObject], key, relationObject[nestedObject].operator)
          const operator = getOperator(relationObject, nestedObject, relationObject[nestedObject].value)
          if (value !== null && value.length > 0 && operator !== null) {
            const query = `{ column: ${relationObject[nestedObject].column.toUpperCase()}, operator: ${operator}, value: ${value} }`
            arrayAnd.push(query)
          }
        }
      }
    }
    if (arrayAnd.length > 0) {
      return `${key}: {
            AND: [
              ${arrayAnd}
            ]
          }`
    } else {
      return ''
    }
  } else {
    return ''
  }
}

function countRelationQuery(object: any, key: any) {
  const relationObject = object[key]
  let countRelationQuery = 0
  if (typeof relationObject === 'object') {
    for (const nestedObject in relationObject) {
      if (key === 'filterField') {
        if (typeof relationObject[nestedObject] === 'object') {
          if (relationObject[nestedObject].value !== undefined && relationObject[nestedObject].value !== '' && relationObject[nestedObject].value !== null) {
            const value = getValue(relationObject[nestedObject], key, relationObject[nestedObject].operator)
            if (value !== null && value.length > 0) {
              countRelationQuery++
            }
          }
        }
      } else if (typeof relationObject[nestedObject] === 'object' && 'column' in relationObject[nestedObject]) {
        if (relationObject[nestedObject].value !== undefined && relationObject[nestedObject].value !== '' && relationObject[nestedObject].value !== null) {
          const value = getValue(relationObject[nestedObject], key, relationObject[nestedObject].operator)
          const operator = getOperator(relationObject, nestedObject, relationObject[nestedObject].value)
          if (value !== null && value.length > 0 && operator !== null) {
            countRelationQuery++
          }
        }
      }
    }
    return countRelationQuery
  } else {
    return 0
  }
}

function generateWhereQuery(object: any) {
  const arrayAnd: any[] = []
  const arrayHas: any[] = []
  const arrayOr: any[] = []
  const whereObject = object.where

  for (const whereKey in whereObject) {
    // Handle OR conditions
    if (whereKey === 'OR') {
      const orConditions = whereObject[whereKey]
      if (Array.isArray(orConditions)) {
        orConditions.forEach((condition) => {
          if (condition.AND) {
            // Handle AND conditions within OR
            const andArray = condition.AND.map((andCondition: any) => {
              const column = andCondition.column
              const operator = andCondition.operator
              let value = andCondition.value

              if (typeof value === 'object' && 'from' in value && 'to' in value) {
                const { from, to } = value
                if (from !== '' && from !== null && to !== '' && to !== null) {
                  value = `["${from}","${to}"]`
                } else if (from !== '' && (to === '' || to === null)) {
                  value = `"${from}"`
                } else if ((from === '' || from === null) && to !== '') {
                  value = `"${to}"`
                }
              } else {
                value = typeof value === 'string' ? `"${value}"` : value
              }

              return `{ column: ${column}, operator: ${operator}, value: ${value} }`
            })
            arrayOr.push(`{AND: [${andArray.join(', ')}]}`)
          } else {
            // Handle direct conditions within OR
            const column = condition.column
            const operator = condition.operator
            let value = condition.value
            if (typeof value === 'object' && 'from' in value && 'to' in value) {
              const { from, to } = value
              if (from !== '' && from !== null && to !== '' && to !== null) {
                value = `["${from}","${to}"]`
              } else if (from !== '' && (to === '' || to === null)) {
                value = `"${from}"`
              } else if ((from === '' || from === null) && to !== '') {
                value = `"${to}"`
              }
            } else {
              value = typeof value === 'string' ? `"${value}"` : value
            }
            arrayOr.push(`{ column: ${column}, operator: ${operator}, value: ${value} }`)
          }
        })
      }
    } else if (whereObject[whereKey].value !== undefined && whereObject[whereKey].value !== null && whereObject[whereKey].value !== '') {
      // Handle regular conditions (existing logic)
      const column = getColumnName(whereObject, whereKey)
      const operator = getOperator(whereObject, whereKey, whereObject[whereKey].value)
      const value = getValue(whereObject[whereKey], whereKey, operator)
      if (value !== null && value.length > 0 && operator !== null) {
        const query = `{ column: ${column.toUpperCase()}, operator: ${operator}, value: ${value} }`
        arrayAnd.push(query)
      }
    }
    // Handle HAS conditions (existing logic)
    if (whereObject[whereKey].condition && whereObject[whereKey].relation) {
      if (whereObject[whereKey].condition.value) {
        const relation = whereObject[whereKey].relation
        const condition = whereObject[whereKey].condition
        const query = `relation: "${relation}" condition: { column: ${condition.column.toUpperCase()}, operator: ${condition.operator}, value: "${condition.value}" }`
        arrayHas.push(query)
      }
    }
  }

  // Construct the final where clause
  let whereClause = ''
  if (arrayAnd.length > 0 || arrayOr.length > 0 || arrayHas.length > 0) {
    whereClause = 'where: {'
    
    // Add AND conditions if they exist
    if (arrayAnd.length > 0) {
      whereClause += `AND: [${arrayAnd}]`
    }
    
    // Add OR conditions if they exist
    if (arrayOr.length > 0) {
      if (arrayAnd.length > 0) whereClause += ', '
      whereClause += `OR: [${arrayOr}]`
    }
    
    // Add HAS conditions if they exist
    if (arrayHas.length > 0) {
      if (arrayAnd.length > 0 || arrayOr.length > 0) whereClause += ', '
      whereClause += `HAS: {${arrayHas}}`
    }
    
    whereClause += '}'
  }

  return whereClause
}

function generateStringQuery(object: any) {
  const arrayString = []
  const stringObject = object.filterField
  for (const stringKey in stringObject) {
    if (stringObject[stringKey].value !== undefined && stringObject[stringKey].value !== null && stringObject[stringKey].value !== '') {
      let query = ''
      if (typeof stringObject[stringKey].value === 'object') {
        if (Array.isArray(stringObject[stringKey].value)) {
          if (stringObject[stringKey].value?.length) {
            query = `${stringKey} : [${stringObject[stringKey].value.map((x: any) => !isNaN(x) ? x : `"${x}"`).join(',')}]`
          }
        } else {
          query = `${stringKey}: {`
          Object.keys(stringObject[stringKey].value).forEach((key) => {
            if (!isNaN(stringObject[stringKey].value[key])) {
              query += `${key}: ${stringObject[stringKey].value[key]}, `
            } else {
              query += `${key}: "${stringObject[stringKey].value[key]}", `
            }
          })
          query += '}'
        }
      } else if (typeof stringObject[stringKey].value === 'boolean') {
        query = `${stringKey} : ${stringObject[stringKey].value}`
      } else {
        query = `${stringKey} : "${stringObject[stringKey].value}"`
      }
      arrayString.push(query)
    } else if (typeof stringObject[stringKey] === 'object' && (stringObject[stringKey].AND || stringObject[stringKey].OR)) {
      if (stringObject[stringKey].AND) {
        let query = `${stringKey} :{ AND: [`
        for (const andKey in stringObject[stringKey].AND) {
          query += `{
            column: "${stringObject[stringKey].AND[andKey].column.toLowerCase()}",
            operator: "${stringObject[stringKey].AND[andKey].operator}",
            value: "${stringObject[stringKey].AND[andKey].value}"
          },`
        }
        query += ']}'
        arrayString.push(query)
      } else if (stringObject[stringKey].OR) {
        let query = `${stringKey} :{ OR: [`
        for (const andKey in stringObject[stringKey].OR) {
          query += `{
            column: "${stringObject[stringKey].OR[andKey].column.toLowerCase()}",
            operator: "${stringObject[stringKey].OR[andKey].operator}",
            value: "${stringObject[stringKey].OR[andKey].value}"
          },`
        }
        query += ']}'
        arrayString.push(query)
      }
    }
  }

  return arrayString
}

function countWhereQuery(object: any) {
  let totalQueryNumber = 0
  const whereObject = object.where
  for (const whereKey in whereObject) {
    if (whereObject[whereKey].value !== undefined && whereObject[whereKey].value !== null && whereObject[whereKey].value !== '') {
      const operator = getOperator(whereObject, whereKey, whereObject[whereKey].value)
      const value = getValue(whereObject[whereKey], whereKey, operator)
      if (value !== null && value.length > 0 && operator !== null) {
        totalQueryNumber++
      }
    }
    if (whereObject[whereKey].condition) {
      if (whereObject[whereKey].condition.value) {
        totalQueryNumber++
      }
    }
  }
  return totalQueryNumber
}

function getColumnName(object: any, key: any) {
  let column = null
  if ('column' in object[key]) {
    column = object[key].column.toUpperCase()
  } else {
    column = key.toUpperCase()
  }
  return column
}
function getOperator(object: any, key: any, value: any) {
  let operator = 'EQ'
  if (isRangeField(value)) {
    if (value.from !== '' && (value.to === '' || value.to === null)) {
      operator = 'GTE'
    } else if ((value.from === '' || value.from === null) && value.to !== '') {
      operator = 'LTE'
    } else if (value.from !== '' && value.from !== null && value.to !== '' && value.to !== null) {
      operator = 'BETWEEN'
    }
    return operator
  } else if (key) {
    if ('operator' in object[key]) {
      operator = object[key].operator
    }
    return operator
  } else {
    if ('operator' in object) {
      operator = object.operator
    }
    return operator
  }
}

function isRangeField(object: any) {
  // Check if it is a object and has from and to in the value property
  if (typeof object === 'object' && 'from' in object && 'to' in object) {
    return true
  } else {
    return false
  }
}

function getValue(object: any, key: any, operator: any) {
  let value = null
  if ('value' in object) {
    if (isRangeField(object.value)) {
      const from = object.value.from
      const to = object.value.to
      if ((from === null && to === null) || (from === '' && to === '')) {
        value = null
      } else if (from !== '' && (to === '' || to === null)) {
        value = '"' + from + '"'
      } else if ((from === '' || from === null) && to !== '') {
        value = '"' + to + '"'
      } else if (from !== '' && from !== null && to !== '' && to !== null) {
        value = '["' + from + '","' + to + '"]'
      }
    } else if (typeof (object.value) === 'string') {
      value = (operator === 'LIKE') ? '"%' + object.value + '%"' : '"' + object.value + '"'
    } else if (typeof (object.value) === 'boolean') {
      value = (object.value === true) ? '"1"' : '"0"'
    } else if (typeof (object.value) === 'number') {
      value = object.value ? object.value : ''
    } else if (operator === 'IN' && Array.isArray(object.value)) {
      value = '[' + object.value.map((x: any) => (typeof x === 'string') ? '"' + x + '"' : x).join(',') + ']'
    } else {
      value = '"' + object.value + '"'
    }
  } else {
    throw new Error(`Value parameter is required in the ${key} property`)
  }
  return value
}

function isOrderByObjectCorrect(orderBy: any) {
  if (orderBy !== null) {
    if (
      'column' in orderBy && orderBy.column !== null &&
      'order' in orderBy && orderBy.order !== null &&
      'page' in orderBy && orderBy.page !== null &&
      'first' in orderBy && orderBy.first !== null
    ) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

function isSearchObjectCorrect(searchObject: any) {
  if (searchObject !== null) {
    if (
      'field' in searchObject && searchObject.field !== null &&
      'value' in searchObject && searchObject.value !== null
    ) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export function generateFilterQuery(queryName: any, queryObject: any, orderBy: any = null, searchField: any = null, subselection: any = null) {
  let queryWhere = ''
  let queryString: any = ''
  const relations = []
  for (const key in queryObject) {
    if (key === 'where') {
      queryWhere = generateWhereQuery(queryObject)
    } else if (key === 'filterField') {
      queryString = generateStringQuery(queryObject)
    } else {
      const relationQuery = generateRelationQuery(queryObject, key)
      if (relationQuery.length > 0) {
        relations.push(relationQuery)
      }
    }
  }
  let orderByString = ''
  if (isOrderByObjectCorrect(orderBy)) {
    orderByString = `orderBy: { column: ${orderBy.column}, order: ${orderBy.order} },
        first: ${orderBy.first},
        page: ${orderBy.page},`
  }

  if (relations.length === 0 && queryWhere.length === 0 && !isOrderByObjectCorrect(orderBy)) {
    queryWhere = 'orderBy: [{ column: ID, order: DESC }]'
  }

  let searchString = ''

  if (searchField) {
    if (Array.isArray(searchField)) {
      searchString = searchField.map((field: { field: string; value: any }) => {
        if (Array.isArray(field.value)) {
          return `${field.field}: [${field.value.map((v: any) => typeof v === 'string' ? `"${v}"` : v)}]`
        }
        return `${field.field}: ${typeof field.value === 'string' ? `"${field.value}"` : field.value}`
      }).join(',\n        ')
    } else if (isSearchObjectCorrect(searchField)) {
      if (typeof searchField.value === 'string') {
        searchString = `${searchField.field} : "${searchField.value}"`
      } else if (typeof searchField.value === 'object') {
        if (searchField.field === 'where') {
          if (searchField.value.OR) {
            if (queryWhere) {
              queryWhere = queryWhere.replace(']}', '], ' + searchWhereObjectOr(searchField) + '}')
            } else {
              searchString = `${searchField.field}: {${searchWhereObjectOr(searchField)}}`
            }
          } else if (searchField.value.AND) {
            if (queryWhere) {
              queryWhere = queryWhere.replace(']}', '], ' + searchWhereObjectAnd(searchField) + '}')
            } else {
              searchString = `${searchField.field}: {${searchWhereObjectAnd(searchField)}}`
            }
          }
        } else {
          searchString = `${searchField.field}: {
            column: ${searchField.value.column},
            operator: ${searchField.value.operator},
            value: "${searchField.value.value}"
          }`
        }
      }
    }
  }

  const glqObject = `{
      ${queryName} (
        ${orderByString}
        ${searchString}
        ${queryString}
        ${queryWhere}
        ${relations}
      ) ${subselection !== null && subselection.length > 0 ? subselection : ''}
      }`
  return gql`${glqObject}`
}

export function countActiveFilters(queryObject: any) {
  let countWhereNumber = 0
  let countRelationQueryNumber = 0
  for (const key in queryObject) {
    if (key === 'where') {
      countWhereNumber += countWhereQuery(queryObject)
    } else {
      countRelationQueryNumber += countRelationQuery(queryObject, key)
    }
  }
  return countWhereNumber + countRelationQueryNumber
}

function searchWhereObjectOr(searchField: any) {
  let object = 'OR: ['
  for (var i = 0; i < searchField.value.OR.length; i++) {
    if (i === searchField.value.OR.length - 1) {
      object += `{column: ${searchField.value.OR[i].column}, operator: ${searchField.value.OR[i].operator}, value: "${searchField.value.OR[i].value}"}`
    } else {
      object += `{column: ${searchField.value.OR[i].column}, operator: ${searchField.value.OR[i].operator}, value: "${searchField.value.OR[i].value}"},`
    }
  }
  object += ']'
  return object
}

function searchWhereObjectAnd(searchField: any) {
  let object = 'AND: ['
  for (var i = 0; i < searchField.value.AND.length; i++) {
    if (i === searchField.value.AND.length - 1) {
      object += `{column: ${searchField.value.AND[i].column}, operator: ${searchField.value.AND[i].operator}, value: "${searchField.value.AND[i].value}"}`
    } else {
      object += `{column: ${searchField.value.AND[i].column}, operator: ${searchField.value.AND[i].operator}, value: "${searchField.value.AND[i].value}"},`
    }
  }
  object += ']'
  return object
}